import { Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";
import { RadioButton } from "../RadioButton";

interface DetailedSelectorItemProps<T> {
  title: string;
  description: string | ReactNode;
  value: T;
  onClick: (newValue: T) => void;
  checked: boolean;
}

interface DetailedSelectorProps<T> {
  value: T;
  onChange: (newValue: T) => void;
  items: Omit<DetailedSelectorItemProps<T>, "checked" | "onClick">[];
}

function BlockRadioButtonItem<T>({
  description,
  title,
  onClick,
  checked,
  value,
}: DetailedSelectorItemProps<T>) {
  return (
    <label
      onClick={() => onClick(value)}
      className={clsx(
        "rounded-lg border-solid border grow flex flex-col p-3 cursor-pointer transition-colors ease-in duration-100 w-full",
        checked
          ? "bg-white border-gray-200"
          : "border-transparent hover:bg-gray-100"
      )}
    >
      <div className="flex space-x-2 items-center">
        <RadioButton checked={checked} />
        <Typography variant="caption" className="text-gray-500">
          {title}
        </Typography>
      </div>
      {typeof description === "string" ? (
        <Typography variant="caption2" className="mt-1 text-gray-500">
          {description}
        </Typography>
      ) : (
        description
      )}
    </label>
  );
}

export function DetailedSelector<T>({
  onChange,
  value,
  items,
}: DetailedSelectorProps<T>) {
  return (
    <div className="flex bg-gray-150 p-1 rounded-lg space-x-1 w-full overflow-hidden">
      {items.map((item) => (
        <BlockRadioButtonItem
          key={item.title}
          checked={value === item.value}
          onClick={() => onChange(item.value)}
          description={item.description}
          title={item.title}
          value={item.value}
        />
      ))}
    </div>
  );
}
