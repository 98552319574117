import {
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getRoot,
} from "lexical";

export const $initializePlainTextEditorValue = (content?: string) => {
  if (!content) return;

  const root = $getRoot();

  root.clear();

  const paragraphNode = $createParagraphNode();
  const lines = content.split("\n");

  lines.forEach((line, index) => {
    const isLastLine = index === lines.length - 1;

    paragraphNode.append($createTextNode(line));

    if (!isLastLine) {
      paragraphNode.append($createLineBreakNode());
    }
  });

  root.append(paragraphNode);
};

export const $getPlainTextEditorContent = () => {
  const root = $getRoot();

  return root.getTextContent();
};
