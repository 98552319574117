import { matchRoutes, RouteMatch, useLocation } from "react-router-dom";
import * as paths from "ui/Router/paths";

const getMatchedPaths = (
  pathList: Record<string, string | Record<string, string>>
): { path: string }[] => {
  return Object.values(pathList).flatMap((value) => {
    if (typeof value === "string") {
      return [{ path: value }];
    }

    if (typeof value === "object") {
      return getMatchedPaths(value);
    }

    return [];
  });
};

export const useCurrentPath = () => {
  const location = useLocation();

  const [routeMatch] = matchRoutes(
    getMatchedPaths(paths),
    location
  ) as RouteMatch[];

  return routeMatch?.route?.path ?? "";
};
