import clsx from "clsx";
import React, { FC } from "react";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  padding?: "sm" | "md" | "lg";
  elevateOnHover?: boolean;
}

export const Card: FC<CardProps> = ({
  elevateOnHover,
  padding = "md",
  className,
  children,
  ...props
}) => (
  <div
    className={clsx(
      "border border-solid rounded-xl border-gray-150 shadow-sm ease-linear duration-150 bg-white",
      className,
      {
        "p-3": padding === "sm",
        "p-5": padding === "md",
        "p-7": padding === "lg",
        "hover:shadow-lg transition-shadow": elevateOnHover,
      }
    )}
    {...props}
  >
    {children}
  </div>
);
