import { Check, CopyAll } from "@mui/icons-material";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { delayAsync } from "application/utils";
import { FC, ReactElement, useCallback, useState } from "react";

interface CopyButtonProps extends Omit<IconButtonProps, "children"> {
  icon?: ReactElement;
  textToCopy?: string;
  overrideCopyFn?: () => Promise<void> | void;
}

export const CopyButton: FC<CopyButtonProps> = ({
  textToCopy,
  icon = <CopyAll />,
  overrideCopyFn,
  ...props
}) => {
  const [displayCopyFeedback, setDisplayCopyFeedback] = useState(false);

  const onCopy = useCallback(async () => {
    if (!textToCopy) return;
    await navigator.clipboard.writeText(textToCopy);
  }, [textToCopy]);

  const handleClick = useCallback(async () => {
    overrideCopyFn ? await overrideCopyFn() : await onCopy();

    setDisplayCopyFeedback(true);

    await delayAsync(5000);

    setDisplayCopyFeedback(false);
  }, [onCopy, overrideCopyFn]);

  return (
    <Tooltip title={displayCopyFeedback ? "Copied!" : "Copy"}>
      <IconButton {...props} onClick={handleClick}>
        {displayCopyFeedback ? <Check /> : icon}
      </IconButton>
    </Tooltip>
  );
};
