import { SxProps, Theme, useTheme } from "@mui/material";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import { clsxm } from "application/utils";
import clsx from "clsx";
import { FC } from "react";

interface LinkTabsProps extends Omit<TabsProps, "sx"> {
  fullWidth?: boolean;
  height?: string;
  wrapperClassName?: string;
}

export const LINK_TABS_HEIGHT = "68px";

export const LinkTabs: FC<LinkTabsProps & { sx?: SxProps<Theme> }> = ({
  value,
  children,
  fullWidth,
  height,
  wrapperClassName,
  sx,
  ...props
}) => {
  const { customColors } = useTheme();

  return (
    <div className={clsxm("flex justify-center", wrapperClassName)}>
      <Tabs
        className={clsx("flex", "justify-center", fullWidth && "w-full")}
        sx={{
          borderBottom: `1px solid ${customColors.gray?.[150]}`,
          height: height || LINK_TABS_HEIGHT,
          "& .MuiTabs-indicator": {
            height: 4,
            borderRadius: 10,
          },
          ...sx,
        }}
        variant="scrollable"
        allowScrollButtonsMobile
        value={value}
        {...props}
      >
        {children}
      </Tabs>
    </div>
  );
};
