import { ReactComponent as ChevronRightSVG } from "assets/svg/chevron-right.svg";
import clsx from "clsx";
import { noop } from "lodash";
import React, { FC, ReactNode } from "react";
import { ActionDescription, Loading, Card } from "ui/components";

interface CardActionProps extends React.ComponentPropsWithoutRef<"div"> {
  label?: string | ReactNode;
  description?: string | React.ReactElement;
  endAdornment?: React.ReactNode | React.ReactElement[];
  loading?: boolean;
  elevateOnHover?: boolean;
  verticalOnSmallDevices?: boolean;
  endAdornmentClassName?: string;
  beta?: boolean;
  icon?: ReactNode;
}

const LoadingOverflow: FC = () => (
  <div className="absolute left-0 top-0 w-full h-full bg-gray-400 bg-opacity-30 p-0">
    <Loading />
  </div>
);

const CardAction = ({
  label = "",
  description = "",
  endAdornment,
  className,
  loading,
  elevateOnHover = true,
  onClick,
  verticalOnSmallDevices,
  endAdornmentClassName,
  beta,
  icon,
  ...props
}: CardActionProps) => {
  return (
    <Card
      elevateOnHover={elevateOnHover && !loading}
      className={clsx(
        "relative overflow-hidden flex",
        {
          "cursor-pointer": onClick && !loading,
        },
        verticalOnSmallDevices
          ? "flex-col md:flex-row items-start md:justify-between md:items-center"
          : "justify-between items-center",
        className
      )}
      onClick={loading ? noop : onClick || noop}
      {...props}
    >
      <div className="flex items-center w-full overflow-hidden">
        {icon && (
          <div className="mr-2 shrink-0 w-10 h-10 rounded-full p-3 border border-solid border-gray-150 bg-gray-100 text-gray-400">
            {icon}
          </div>
        )}
        <ActionDescription
          beta={beta}
          label={label}
          description={description}
        />
      </div>
      <div className={clsx("pl-2 shrink-0", endAdornmentClassName)}>
        {endAdornment || <ChevronRightSVG />}
      </div>
      {loading && <LoadingOverflow />}
    </Card>
  );
};

export default CardAction;
