import { Typography } from "@mui/material";
import FunctionsIcon from "@mui/icons-material/Functions";
import { FC } from "react";
import { IFunctionMetadata } from "types/assistant.service";

interface FunctionCitationProps {
  functionCitation: IFunctionMetadata;
}

export const FunctionCitation: FC<FunctionCitationProps> = ({
  functionCitation,
}) => (
  <div className="flex flex-row justify-center items-center px-2 py-1 mt-2 mr-4 rounded-md border border-solid border-gray-200">
    <FunctionsIcon className="h-5" />
    <Typography
      variant="caption"
      className="ml-1"
      title={functionCitation.functionName}
    >
      {`Source: ${functionCitation.source}`}
    </Typography>
  </div>
);
