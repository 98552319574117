import { FC, useCallback, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Check, ContentCopy } from "@mui/icons-material";
import ReactSyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightEighties } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { copyTextToClipboard, delayAsync } from "application/utils";

export interface MarkdownSyntaxHighlightProps {
  className: string;
  children: string;
}

export const MarkdownSyntaxHighlight: FC<MarkdownSyntaxHighlightProps> = ({
  children,
  className,
}) => {
  const language = className?.split("-")?.pop() || "";
  const [displayCopyFeedback, setDisplayCopyFeedback] = useState(false);

  const handleClick = useCallback(async () => {
    await copyTextToClipboard(children);
    setDisplayCopyFeedback(true);

    await delayAsync(5000);

    setDisplayCopyFeedback(false);
  }, [children]);

  return (
    <div>
      <div className="bg-zinc-700 text-white px-4 py-2 w-full rounded-t-md flex justify-between items-center">
        {language && (
          <Typography variant="hairline2" className="text-white capitalize">
            {language}
          </Typography>
        )}
        <Button
          startIcon={
            displayCopyFeedback ? (
              <Check style={{ height: 15 }} />
            ) : (
              <ContentCopy style={{ height: 15 }} />
            )
          }
          onClick={() => {
            if (displayCopyFeedback) return;
            handleClick();
          }}
          className="h-6 text-white hover:bg-gray-50 focus:bg-gray-50 hover:bg-opacity-20 focus:bg-opacity-40"
        >
          <Typography variant="caption" className="text-inherit font-bold">
            {displayCopyFeedback ? "Copied" : "Copy code"}
          </Typography>
        </Button>
      </div>
      <ReactSyntaxHighlighter
        showLineNumbers
        wrapLongLines
        language={language}
        style={tomorrowNightEighties}
        codeTagProps={{ className: "font-mono" }}
        customStyle={{
          marginTop: 0,
          borderRadius: 6,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          paddingBottom: 12,
        }}
      >
        {children}
      </ReactSyntaxHighlighter>
    </div>
  );
};
