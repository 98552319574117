import { Badge, IconButton } from "@mui/material";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { clsxm } from "application/utils";
import { ReactComponent as IconNotifications } from "assets/svg/icon-notifications.svg";
import clsx from "clsx";
import { noop } from "lodash";
import {
  MouseEvent,
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as paths from "ui/Router/paths";
import { notificationsPath } from "ui/Router/paths";
import { CurrentUserAvatar } from "ui/components";
import { useUnreadNotificationCount } from "ui/hooks/useUnreadNotificationCount";
import { useTypedSelector } from "../../hooks/useTypeSelector";
import UserMenu from "./UserMenu/UserMenu";

interface AuthorizedBlockProps {
  headerRef?: MutableRefObject<HTMLDivElement | null>;
  light?: boolean;
  hideActions: boolean;
}

export const NAV_PROFILE_BUTTON_ID = "nav-profile-button";

export const AuthorizedUserProfile = ({
  headerRef,
  light,
  hideActions,
}: AuthorizedBlockProps) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const anchorToHeader = smallScreen && headerRef?.current;
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const { isOpenProfileDisabled } = useTypedSelector((state) => state.UI);

  const { data: unreadCountResponse } = useUnreadNotificationCount({
    refreshInterval: 300000,
  });

  const notificationsCount = useMemo(() => {
    return unreadCountResponse?.data.response?.count;
  }, [unreadCountResponse]);

  useEffect(() => {
    closeMenu();
  }, [location]);

  const onProfileClick = (event: MouseEvent<HTMLElement>) => {
    if (hideActions) return;

    setOpen(!open);
    if (!open) {
      setAnchorEl(anchorToHeader ? headerRef.current : event.currentTarget);
    }
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const isBullpenProductPage = location.pathname === `/${paths.bullpenProduct}`;

  return (
    <div className="flex items-center space-x-2">
      {!hideActions && (
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/" + notificationsPath);
          }}
        >
          <Badge
            className="text-gray-500 hover:text-black"
            color="primary"
            badgeContent={notificationsCount}
            max={99}
          >
            <IconButton
              className={clsxm(
                "hover:text-primary-400 transition-colors duration-200 ease-linear",
                {
                  "text-gray-400": !light || (light && isBullpenProductPage),
                  "text-white hover:text-gray-300": light,
                }
              )}
            >
              <IconNotifications />
            </IconButton>
          </Badge>
        </div>
      )}
      <CurrentUserAvatar
        id={NAV_PROFILE_BUTTON_ID}
        className="inline-flex h-8 w-8 text-[1rem] cursor-pointer"
        onClick={isOpenProfileDisabled ? undefined : onProfileClick}
      />
      <Popper
        className={clsx({})}
        open={!smallScreen && open}
        anchorEl={anchorEl}
        placement={anchorToHeader ? "bottom-start" : "bottom-end"}
        transition
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
        placeholder=""
        onPointerEnterCapture={noop}
        onPointerLeaveCapture={noop}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={{
              enter: 350,
              exit: 350,
            }}
          >
            <Paper className="w-full h-full rounded-none sm:rounded-[12px] shadow-none sm:shadow-depth-2 border-2 border-solid border-gray-200 sm:border sm:mt-2 bg-gray-100">
              <UserMenu closeMenu={closeMenu} smallScreen={false} />
            </Paper>
          </Fade>
        )}
      </Popper>
      <Modal
        className={clsx({
          "top-[57px]": anchorToHeader,
          "top-[67px]": !smallScreen,
        })}
        open={smallScreen && open}
        onClose={closeMenu}
        closeAfterTransition
        hideBackdrop
        disableAutoFocus
      >
        <Fade in={smallScreen && open}>
          <Paper className="w-full h-full rounded-none sm:rounded-[12px] shadow-none sm:shadow-menu border-0 sm:border sm:mt-2 bg-gray-100">
            <UserMenu closeMenu={closeMenu} smallScreen={true} />
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};
