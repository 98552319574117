import {
  AutoAwesome,
  Close,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, IconButton, Typography } from "@mui/material";
import { FeedbackError } from "application/errors";
import { cleanAssistantMessage, clsxm } from "application/utils";
import clsx from "clsx";
import {
  AssistantAttachableField,
  AssistantThreadMessageRole,
  DealFieldInfoMap,
  DEFAULT_ASSISTANT_ERROR_HANDLING_MESSAGE,
  FieldAssistantType,
} from "domain/assistant";
import { noop, startCase } from "lodash";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import dealAssistantService from "services/dealAssistant.service";
import useSWR from "swr";
import { useAssistantStream, useErrorHandler } from "ui/hooks";
import { EmptyContentScreen } from "../EmptyContentScreen";
import { ConfirmationModal } from "../Modal";
import {
  AssistantFloatingChat,
  IAssistantChatMessage,
} from "./AssistantFloatingChat";
import { AssistantFloatingChatMessage } from "./components";

interface IAssistantFloatingChatContext {
  openChat: (dealId: string, fieldId: string, type: FieldAssistantType) => void;
  closeChat: () => void;
  chatPayload?: {
    dealId: string;
    fieldId: string;
    type: FieldAssistantType;
  };
}

const AssistantFloatingChatContext =
  createContext<IAssistantFloatingChatContext>({
    openChat: noop,
    closeChat: noop,
  });

export const useAssistantFloatingChatContext = () => {
  return useContext(AssistantFloatingChatContext);
};

type RemovePayloadModalType = "delete" | "replace";

interface IRemovePayloadModalRequest {
  type: RemovePayloadModalType;
  payload?: IAssistantFloatingChatContext["chatPayload"];
}

const options = ["Elaborate", "Shorten", "Formalize"];

export const AssistantFloatingChatProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState<string>();
  const [isMinimized, setMinimized] = useState(false);
  const [removePayloadModalType, setRemovePayloadModalType] =
    useState<IRemovePayloadModalRequest | null>(null);
  const [chatPayload, setChatPayload] = useState<
    IAssistantFloatingChatContext["chatPayload"] | undefined
  >();
  const { pathname } = useLocation();
  const { handleError } = useErrorHandler();
  const { readStream, resetValue, streamMessages } = useAssistantStream(
    chatPayload?.dealId || ""
  );

  const { data, mutate } = useSWR(
    threadId &&
      chatPayload?.dealId &&
      `assistant/thread/request/${threadId}/messages`,
    () => {
      return dealAssistantService.getThreadById(
        chatPayload?.dealId as string,
        threadId as string
      );
    },
    {
      refreshInterval: (value) => (value?.hasPendingRuns ? 5000 : 0),
    }
  );

  const openChat = useCallback(
    (dealId: string, fieldId: string, type: FieldAssistantType) => {
      setThreadId(undefined);
      setChatPayload({ dealId, fieldId, type });
    },
    [setChatPayload]
  );

  const requestNewChat = useCallback<IAssistantFloatingChatContext["openChat"]>(
    (dealId, fieldId, type) => {
      if (chatPayload && fieldId !== chatPayload.fieldId) {
        return setRemovePayloadModalType({
          type: "replace",
          payload: { dealId, fieldId, type },
        });
      }

      return openChat(dealId, fieldId, type);
    },
    [chatPayload, openChat]
  );

  const closeChat = useCallback(() => {
    setChatPayload(undefined);
    setThreadId(undefined);
    setMinimized(false);
  }, []);

  const requestCloseChat = useCallback(() => {
    return setRemovePayloadModalType({ type: "delete" });
  }, []);

  const onModalClose = useCallback(() => {
    return setRemovePayloadModalType(null);
  }, []);

  const onModalConfirm = useCallback(() => {
    if (!removePayloadModalType) return;

    const { type, payload } = removePayloadModalType;

    try {
      switch (type) {
        case "replace":
          return openChat(
            payload?.dealId as string,
            payload?.fieldId as string,
            payload?.type as FieldAssistantType
          );
        case "delete":
          return closeChat();
      }
    } finally {
      onModalClose();
    }
  }, [closeChat, onModalClose, openChat, removePayloadModalType]);

  useEffect(() => {
    if (typeof pathname === "string") {
      closeChat();
    }
  }, [closeChat, pathname]);

  const onMessageAdd = useCallback(
    async (message: string) => {
      if (!chatPayload) return { isSuccess: false };

      setLoading(true);

      try {
        if (!threadId)
          throw new FeedbackError(
            "Please, select a thread before adding messages"
          );

        const stream = await dealAssistantService.addThreadMessage(
          chatPayload.dealId,
          threadId,
          message
        );

        await readStream(stream, message);
        await mutate();
        resetValue();

        return { isSuccess: true };
      } catch (e) {
        handleError(e, "It was not possible to generate the thread.");

        return { isSuccess: false };
      } finally {
        setLoading(false);
      }
    },
    [chatPayload, handleError, mutate, readStream, resetValue, threadId]
  );

  const onInit = useCallback(async () => {
    if (!chatPayload) return;
    setLoading(true);

    try {
      const newThread = await dealAssistantService.createAndRunFieldThread(
        chatPayload.dealId,
        chatPayload.fieldId
      );

      setThreadId(newThread.threadId);
      await mutate();
    } catch (e) {
      handleError(e, "It was not possible to generate the thread.");
    } finally {
      setLoading(false);
    }
  }, [chatPayload, handleError, mutate]);

  const messages = useMemo<IAssistantChatMessage[]>(() => {
    if (!threadId || !chatPayload) return [];

    const validMessages = [...(data?.messages || []), ...streamMessages];

    const parsedMessages: IAssistantChatMessage[] = validMessages.map(
      (item) => {
        if (item.role === AssistantThreadMessageRole.User) {
          return item;
        }

        if (
          item.role === AssistantThreadMessageRole.Assistant &&
          item.content.includes("myfiles_browser")
        ) {
          return {
            ...item,
            content: DEFAULT_ASSISTANT_ERROR_HANDLING_MESSAGE,
            error: true,
          };
        }

        return {
          ...item,
          textToCopy: cleanAssistantMessage(item.content),
          content: cleanAssistantMessage(item.content),
        };
      }
    );

    return [
      {
        content: "Create field content",
        threadId,
        createdAt: new Date().toISOString(),
        role: AssistantThreadMessageRole.User,
        assistantId: "",
        messageId: "",
        openAiId: "",
        updatedAt: "",
      },
      ...parsedMessages,
    ];
  }, [data?.messages, streamMessages, threadId, chatPayload]);

  const isLastMessageFromUser = useMemo(() => {
    return [...messages].pop()?.role !== AssistantThreadMessageRole.Assistant;
  }, [messages]);

  return (
    <AssistantFloatingChatContext.Provider
      value={{
        openChat: requestNewChat,
        closeChat: requestCloseChat,
        chatPayload,
      }}
    >
      {children}

      {chatPayload && (
        <div
          className={clsx(
            "z-40 bg-white shadow-md fixed right-4 bottom-0 border border-solid border-gray-200 rounded-t-lg flex flex-col",
            isMinimized
              ? "cursor-pointer w-[319px]"
              : "h-[864px] max-w-[calc(100vw_-_32px)] w-[519px] max-h-[calc(100%_-_120px)]"
          )}
          onClick={isMinimized ? () => setMinimized(false) : undefined}
        >
          <AssistantFloatingChat
            isLoading={isLoading}
            onMessageAdd={onMessageAdd}
            disabled={data?.hasPendingRuns || !threadId}
            key={chatPayload.fieldId}
            isMinimized={isMinimized}
            header={
              <div
                onClick={() => {
                  setMinimized(true);
                }}
                className={clsxm(
                  "p-4 flex flex-nowrap overflow-hidden w-full items-center shrink-0",
                  "cursor-pointer"
                )}
              >
                <div className="rounded-full border border-solid border-primary flex items-center text-primary bg-white py-1 px-2">
                  <AutoAwesome className="mr-1 text-[1rem] text-inherit" />
                  <Typography variant="caption2" className="text-primary">
                    AI
                  </Typography>
                </div>
                <Typography
                  variant="caption"
                  className="text-primary-600 ml-1 truncate font-bold"
                >
                  -{" "}
                  {DealFieldInfoMap[
                    chatPayload?.fieldId as AssistantAttachableField
                  ]?.label ?? startCase(chatPayload.fieldId)}
                </Typography>
                <div className="flex space-x-4 items-center shrink-0 ml-2 ml-auto">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setMinimized(!isMinimized);
                    }}
                    className="text-primary h-8 w-8"
                  >
                    {isMinimized ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                  {!isMinimized && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        requestCloseChat();
                      }}
                      className="text-primary h-8 w-8"
                    >
                      <Close />
                    </IconButton>
                  )}
                </div>
              </div>
            }
            formToolbar={
              <div className="left-2 flex space-x-1 mb-4">
                {options.map((item) => (
                  <Button
                    key={item}
                    onClick={() => onMessageAdd(item)}
                    disabled={data?.hasPendingRuns || !threadId || isLoading}
                    variant="outlined"
                    className="h-8 px-3 bg-white shadow-sm"
                    size="small"
                  >
                    <Typography
                      variant="caption"
                      className="text-inherit font-bold"
                    >
                      {item}
                    </Typography>
                  </Button>
                ))}
              </div>
            }
          >
            {messages.length > 0
              ? [
                  ...messages.map((message) => (
                    <AssistantFloatingChatMessage
                      key={message.messageId}
                      message={message}
                    />
                  )),
                  ...(isLastMessageFromUser
                    ? [<AssistantFloatingChatMessage loading key="loading" />]
                    : []),
                ]
              : [
                  <div className="mt-auto" key="emptyScreen">
                    <EmptyContentScreen
                      title="Generate field value"
                      subtitle="Click to generate content, then we can refine it together"
                      icon={<AutoAwesome />}
                    >
                      <div className="mt-auto mx-auto mb-10">
                        <LoadingButton
                          startIcon={<AutoAwesome />}
                          onClick={onInit}
                          loading={isLoading}
                          className="h-8 px-3"
                          variant="contained"
                        >
                          <Typography
                            variant="caption"
                            className="text-inherit font-bold"
                          >
                            Generate
                          </Typography>
                        </LoadingButton>
                      </div>
                    </EmptyContentScreen>
                  </div>,
                ]}
          </AssistantFloatingChat>
        </div>
      )}

      {removePayloadModalType && (
        <ConfirmationModal
          title={
            removePayloadModalType.type === "delete"
              ? "Delete thread"
              : "Replace thread"
          }
          isOpen
          onClose={onModalClose}
          onConfirm={onModalConfirm}
          onCancel={onModalClose}
        >
          <div>
            <Typography variant="body2" component="p">
              {removePayloadModalType?.type === "delete"
                ? "Are you sure you want to delete this thread?"
                : "Are you sure you want to replace the current thread?"}
            </Typography>
            <Typography variant="body2" className="italic mt-2" component="p">
              The progress made in this thread will be lost, necessitating the
              generation of new content.
            </Typography>
          </div>
        </ConfirmationModal>
      )}
    </AssistantFloatingChatContext.Provider>
  );
};
