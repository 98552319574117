import { selectCurrentProjectId } from "redux/ProjectReports/reducer";
import projectService from "services/project.service";
import useSWR, { SWRConfiguration } from "swr";
import { IProjectUserPermissions } from "types/project.service";
import useTypedSelector from "../useTypeSelector";

export const useProjectPermissions = (
  config?: SWRConfiguration<IProjectUserPermissions>
) => {
  const alias = useTypedSelector(selectCurrentProjectId);

  const { data: permissions, isLoading } = useSWR<IProjectUserPermissions>(
    alias && `/project/${alias}/permissions`,
    () => {
      return projectService.getProjectPermissions(alias);
    },
    config
  );

  const loading = isLoading || !alias;

  if (!permissions) {
    return {
      permissions: {
        canEdit: false,
        canView: false,
        canManage: false,
      },
      loading,
    };
  }

  return {
    permissions,
    loading,
  };
};
