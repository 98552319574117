import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { mergeRegister } from "@lexical/utils";
import { Typography, TypographyProps } from "@mui/material";
import { clsxm } from "application/utils";
import {
  $getRoot,
  BLUR_COMMAND,
  COMMAND_PRIORITY_NORMAL,
  FOCUS_COMMAND,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
} from "lexical";
import { FC, MutableRefObject, useCallback, useEffect, useRef } from "react";
import { useErrorHandler } from "ui/hooks";
import {
  $getPlainTextEditorContent,
  $initializePlainTextEditorValue,
} from "./PlainTextEditor.actions";
import styles from "./PlainTextEditor.module.scss";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";

interface PlainTextEditorProps {
  initialContent?: string;
  placeholder?: string;
  placeholderClassName?: string;
  variant?: TypographyProps["variant"];
  className?: string;
  editable?: boolean;
  name?: string;

  onChange?: (value: string) => void;
  onBlur?: (editor: LexicalEditor, value: string) => void;
  onEscape?: (editor: LexicalEditor) => void;
  onFocus?: () => void;
  editorRef?: MutableRefObject<LexicalEditor | null>;
  autoFocus?: boolean;
}

export const PlainTextEditor: FC<PlainTextEditorProps> = ({
  initialContent,
  onChange,
  placeholder = "Insert a message here",
  onBlur,
  onEscape,
  onFocus,
  variant = "body2",
  placeholderClassName,
  className,
  editorRef,
  editable = true,
  autoFocus = false,
  name,
}) => {
  const { handleError } = useErrorHandler();
  const internalRef = useRef<LexicalEditor | null>(null);

  const handleSave = useCallback(() => {
    if (!onChange) return;
    if (!internalRef.current) return;

    onChange(
      internalRef.current
        .getEditorState()
        .read(() => $getPlainTextEditorContent())
    );
  }, [onChange]);

  useEffect(() => {
    if (!internalRef.current) return;

    const editor = internalRef.current;

    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          onFocus?.();

          return true;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          onBlur?.(
            editor,
            editor.getEditorState().read(() => $getRoot().getTextContent())
          );

          return true;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        (e) => {
          e.preventDefault();
          e.stopPropagation();

          onEscape?.(editor);
          return true;
        },
        COMMAND_PRIORITY_NORMAL
      )
    );
  }, [onBlur, onEscape, onFocus]);

  useEffect(() => {
    internalRef.current?.setEditable(editable);
  }, [editable]);

  return (
    <LexicalComposer
      initialConfig={{
        namespace: "PlainTextEditor",
        onError(e) {
          console.error(e);
          handleError(e, "Some error occurred inside the editor.");
        },
        editable,
        editorState() {
          $initializePlainTextEditorValue(initialContent);
        },
      }}
    >
      <PlainTextPlugin
        contentEditable={
          <Typography
            component={ContentEditable}
            name={name}
            placeholder={
              <Typography
                component="span"
                variant={variant}
                className={clsxm(
                  "text-gray-300 absolute user-select-none pointer-events-none w-full overflow-hidden truncate",
                  "left-3.5 top-4",
                  placeholderClassName
                )}
              >
                {placeholder}
              </Typography>
            }
            className={clsxm(
              "text-gray-900 whitespace-pre-wrap outline-none",
              styles.Editable,
              className
            )}
            aria-placeholder="Enter your message"
            tabIndex={-1}
            variant={variant}
          />
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      <OnChangePlugin
        ignoreHistoryMergeTagChange
        ignoreSelectionChange
        onChange={handleSave}
      />
      <EditorRefPlugin
        editorRef={(editor) => {
          if (!editor) return;
          internalRef.current = editor;

          if (!editorRef) return;

          editorRef.current = editor;
        }}
      />
      {editable && autoFocus && <AutoFocusPlugin />}
    </LexicalComposer>
  );
};
