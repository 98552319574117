import { Fullscreen, PresentToAll } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import ModalComponent from "ui/pages/CommonLayout/Modals/ModalComponent";
import { EmptyContentScreen } from "../EmptyContentScreen";
import { MessageListInputForm } from "./components";

interface MessageListInputProps {
  onAddMessage: (message: string) => void;
  onReset?: () => void;
  children?: ReactNode;
}

export const MessageListInput: FC<MessageListInputProps> = ({
  onAddMessage,
  onReset,
  children,
}) => {
  const [isMaximized, setMaximized] = useState(false);

  return (
    <>
      <div className="bg-gray-100 rounded-2xl p-4 border-2 border-solid border-gray-200 grow flex flex-col overflow-hidden">
        {isMaximized ? (
          <EmptyContentScreen
            title="Maximized view"
            subtitle="The messages will be displayed in a full-screen modal"
            icon={<PresentToAll />}
          />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <Typography
                variant="caption2"
                className="text-gray-500 font-semibold"
              >
                Messages
              </Typography>

              <div className="flex items-center gap-2">
                <Button
                  variant="outlined"
                  className="h-6"
                  onClick={onReset}
                  color="warning"
                  size="small"
                >
                  <Typography variant="caption2" className="text-inherit">
                    Reset
                  </Typography>
                </Button>
                <IconButton onClick={() => setMaximized(true)} size="small">
                  <Fullscreen />
                </IconButton>
              </div>
            </div>
            <MessageListInputForm
              children={children}
              onAddMessage={onAddMessage}
              size="small"
            />
          </>
        )}
      </div>
      <ModalComponent
        title="Messages"
        smallTitle
        paperClassName="md:w-[700px] md:h-[750px] max-h-screen"
        open={isMaximized}
        onClose={() => setMaximized(false)}
      >
        <MessageListInputForm
          children={children}
          onAddMessage={onAddMessage}
          size="medium"
        />
      </ModalComponent>
    </>
  );
};
