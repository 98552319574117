import { ChevronLeftRounded } from "@mui/icons-material";
import {
  Avatar,
  Collapse,
  IconButton,
  Tab,
  tabsClasses,
  Typography,
} from "@mui/material";
import { getNameInitials } from "application/utils";
import { text } from "domain/text";
import { Fragment, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  generatePath,
  Link,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  resetCurrentProjectId,
  selectCurrentProjectId,
  setCurrentProject,
} from "redux/ProjectReports/reducer";
import { IProject, IProjectUserPermissions } from "types/project.service";
import { Loading } from "ui/components";
import { BetaTag } from "ui/components/BetaTag";
import { LinkTabs } from "ui/components/LinkTabs";
import { SlimFormPageLayout } from "ui/components/PageLayout/SlimFormPageLayout";
import { ResultScreen } from "ui/components/ResultScreen";
import {
  useCurrentPath,
  useCurrentProject,
  useProjectPermissions,
  useSyndicationLayout,
  useTypedSelector,
} from "ui/hooks";
import * as paths from "ui/Router/paths";
import ProjectSharing from "./ProjectSharing";

interface TabItemDef {
  label: string;
  to: string;
  checkPermission: (permissions: IProjectUserPermissions) => boolean;
  subPaths: string[];
  customPathValidation?: (path: string) => boolean;
  validate?: (project?: IProject) => boolean;
  featureFlag?: string;
  hiddenToSyndication?: boolean;
  beta?: boolean;
}

const items: TabItemDef[] = [
  {
    label: text.DATAROOM_TITLE,
    to: paths.project.projectDataRoomVDR,
    checkPermission: (permissions) => permissions.canView,
    subPaths: [],
    customPathValidation: (path) => {
      return path.includes(paths.project.projectDataRoomVDR);
    },
  },
  {
    label: "Reports",
    to: paths.project.projectReports,
    checkPermission: (permissions) => permissions.canView,
    subPaths: [],
  },
  {
    label: "Assistant",
    to: paths.project.projectAssistant,
    checkPermission: (permissions) => permissions.canView,
    subPaths: [],
  },
];

export const ProjectPageWrapper = () => {
  const dispatch = useDispatch();
  const { alias } = useParams();
  const { data, isLoading: loadingProject } = useCurrentProject();
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const { loading: loadingPermissions, permissions } = useProjectPermissions();
  const { isSyndicate } = useSyndicationLayout();
  const { isContentFullScreen } = useTypedSelector(({ UI }) => UI);
  const currentProjectId = useTypedSelector(selectCurrentProjectId);

  useEffect(() => {
    if (alias && alias !== currentProjectId) {
      dispatch(setCurrentProject(alias));
    }
  }, [currentProjectId, alias, dispatch]);

  const currentTabValue = items.find(
    ({ to, subPaths, customPathValidation }) => {
      if (customPathValidation) {
        return customPathValidation(currentPath || "");
      }

      return [to, ...subPaths].includes(currentPath || "");
    }
  )?.to;

  if (loadingPermissions) {
    return <Loading full />;
  }

  if (!permissions.canView) {
    return (
      <div className="flex flex-col grow relative">
        <SlimFormPageLayout>
          <ResultScreen
            title="Not Allowed"
            subtitle="You don't have permission to access this project"
            variant="warning"
            button={{
              onSubmit: () => {
                if (window.history.length > 1) {
                  return navigate(-1);
                }

                return navigate(`/${paths.projectList}`);
              },
              title: "Go back",
            }}
          />
        </SlimFormPageLayout>
      </div>
    );
  }

  if (loadingProject || !data) {
    return <Loading full />;
  }

  return (
    <div className="w-full flex flex-col">
      <Collapse in={!isContentFullScreen}>
        <div className="w-full border-b border-solid border-gray-200">
          <div className="flex md:flex-row flex-col m9-container overflow-hidden flex-nowrap !py-0 h-20">
            <div className="flex md:items-center shrink-0 py-4">
              <div>
                <Link
                  to={"/" + paths.projectList}
                  onClick={() => {
                    dispatch(resetCurrentProjectId());
                  }}
                >
                  <IconButton className="h-10 w-10 border-2 border-solid">
                    <ChevronLeftRounded className="text-[1rem]" />
                  </IconButton>
                </Link>
              </div>
              <div className="flex ml-4">
                <Avatar src={data.imageUrl} variant="rounded">
                  {getNameInitials(data.name)}
                </Avatar>
                <div className="flex flex-col ml-2">
                  <Typography
                    variant="body2"
                    className="font-bold text-gray-500"
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    variant="caption2"
                    className="font-bold text-gray-400 mt-1"
                  >
                    By {data.ownerName}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="flex-1 px-2 overflow-hidden mx-auto">
              <LinkTabs
                height="100%"
                wrapperClassName="w-full md:w-auto h-full"
                value={currentTabValue}
                sx={{
                  [`& .${tabsClasses.flexContainer}`]: {
                    height: "100%",
                  },
                }}
              >
                {items.map(
                  (
                    {
                      label,
                      checkPermission,
                      to,
                      validate,
                      featureFlag,
                      hiddenToSyndication,
                      beta,
                    },
                    index
                  ) => {
                    if (isSyndicate && hiddenToSyndication) {
                      return null;
                    }

                    if (validate && !validate(data)) {
                      return null;
                    }

                    if (!checkPermission(permissions)) {
                      return null;
                    }

                    if (featureFlag) {
                      return null;
                    }

                    return (
                      <Tab
                        key={index}
                        value={to}
                        className="text-[14px] my-auto px-2 font-bold capitalize gray-400 hover:text-primary-400 transition-colors ease-in"
                        component={Link}
                        to={generatePath("/" + to, { alias })}
                        label={
                          <div className="flex items-center justify-center space-x-1">
                            <div>{label}</div>
                            {beta && <BetaTag />}
                          </div>
                        }
                      />
                    );
                  }
                )}
              </LinkTabs>
            </div>
            {permissions?.canEdit && (
              <div className="shrink-0 flex items-center">
                <ProjectSharing
                  title={`Share '${data.name}'`}
                  className="flex gap-2 items-center md:self-center my-5 md:my-0"
                />
              </div>
            )}
          </div>
        </div>
      </Collapse>

      <div className="flex flex-col grow">
        <Suspense fallback={<Fragment />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};
