import { HighlightOffRounded } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { FC } from "react";

import { ReactNode } from "react";

export interface SidebarHeaderProps {
  icon: ReactNode;
  title: string;
  subtitle?: string;
  onClose: () => void;
}

export const SidebarHeader: FC<SidebarHeaderProps> = ({
  onClose,
  icon,
  title,
  subtitle,
}) => {
  return (
    <div className="p-4 border-b border-0 border-solid border-gray-150 flex justify-between">
      <div className="flex items-center">
        {icon}
        <div className="flex flex-col space-y-1 ml-3">
          <Typography variant="body2" className="text-primary-400 font-bold">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="caption2" className="text-gray-300">
              {subtitle}
            </Typography>
          )}
        </div>
      </div>
      <IconButton
        onClick={() => {
          onClose();
        }}
      >
        <HighlightOffRounded />
      </IconButton>
    </div>
  );
};
