import { Done } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { clsxm } from "application/utils";
import { ReactComponent as IconLink } from "assets/svg/icon-link.svg";
import { ReactComponent as PeopleIcon } from "assets/svg/people-icon.svg";
import { validate } from "email-validator";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  useAddRoleToUserMutation,
  useGetRolesQuery,
  useGetUsersWithRolesQuery,
} from "redux/Api/DataroomPermissionsApi";
import projectService from "services/project.service";
import { Nullable } from "types";
import { Loading, Modal } from "ui/components";
import { AuthorizedUserProfile } from "ui/components/AuthorizedUserProfile/AuthorizedUserProfile";
import {
  useCurrentPath,
  useErrorHandler,
  useProjectAlias,
  useProjectPermissions,
} from "ui/hooks";
import { PredefinedRoles } from "ui/pages/DealDataRoom/DealDataRoom.types";
import { ProjectSharingInput } from "./ProjectSharingInput";
import ProjectSharingPeopleWithAccess from "./ProjectSharingPeopleWithAccess";
import { ProjectSharingRoleSelect } from "./ProjectSharingRoleSelect";
import { ReactComponent as IconCog } from "assets/svg/icon-cog.svg";
import * as paths from "ui/Router/paths";
import { generatePath } from "react-router-dom";
import { Link } from "react-router-dom";

interface ProjectSharingProps {
  className?: string;
  title?: string;
  standalone?: boolean;
  open?: boolean;
  projectId?: string;
  onClose?: () => void;
}

const ProjectSharing = ({
  className,
  title = "Sharing Options",
  standalone = false,
  open = false,
  projectId,
  onClose,
}: ProjectSharingProps) => {
  const { permissions } = useProjectPermissions();
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();
  const [isCopied, setIsCopied] = useState(false);
  const alias = useProjectAlias();
  const [users, setUsers] = useState<string[]>([]);
  const [role, setRole] = useState<Nullable<Option>>(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentPath = useCurrentPath();

  const [addRoleMutation] = useAddRoleToUserMutation();

  const skip = standalone ? !open : !isOpen;

  const {
    data: usersWithAccess,
    refetch,
    isLoading: usersWithRolesLoading,
  } = useGetUsersWithRolesQuery(
    {
      resourceId: projectId ?? alias,
      type: "Project",
    },
    { skip }
  );

  const { data: available, isLoading: getRolesLoading } = useGetRolesQuery(
    {
      resourceId: projectId ?? alias,
      type: "Project",
    },
    { skip }
  );

  const eligibleUsers = users.filter(validate);

  const rolesAvailable = available
    ?.map(({ roleId: value, roleName: label }) => ({
      label,
      value,
    }))
    .filter(({ value }) => value !== PredefinedRoles.ALL);

  const defaultRole = rolesAvailable?.find(
    ({ value }) => value === PredefinedRoles.VIEWER
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const onSave = async () => {
    setIsLoading(true);
    const contacts = {
      contacts: eligibleUsers.map((email) => ({ email })),
    };
    try {
      await projectService.shareProject(projectId ?? alias, contacts);

      setUsers([]);
      setRole(null);

      enqueueSnackbar("Saved!", {
        title: "Successfully updated",
        variant: "success",
      });

      const updatedUsersWithAccess = await projectService.getUsersWithAccess(
        projectId ?? alias
      );

      const usersIds = updatedUsersWithAccess.items
        .filter(({ email }) => eligibleUsers.includes(email))
        .map(({ userId, email }) => userId || email);

      if (role?.value !== PredefinedRoles.VIEWER) {
        await addRoleMutation({
          resourceId: projectId ?? alias,
          type: "Project",
          usersIds,
          roleId: role?.value ?? PredefinedRoles.VIEWER,
        }).unwrap();
        setRole(null);
      }
      refetch();
    } catch (error) {
      handleError(
        error,
        "It was not possible to update the users. Please try again later."
      );
    }
    setIsLoading(false);
  };

  const sharingIsLoading =
    usersWithRolesLoading || getRolesLoading || isLoading;

  return (
    <div className={clsxm(className)}>
      {!standalone && (
        <>
          <Button
            variant="outlined"
            size="small"
            className="mr-2 w-full h-8 shadow-depth-1"
            onClick={handleOpen}
            endIcon={<PeopleIcon />}
          >
            Share
          </Button>
          {permissions.canEdit && (
            <Link
              to={generatePath("/" + paths.project.projectSettings, { alias })}
            >
              <IconButton
                color={
                  currentPath === paths.project.projectSettings
                    ? "primary"
                    : "default"
                }
                className="border-2 h-8 w-8 p-1.5 flex border-solid shadow-depth-1"
              >
                <IconCog className="h-10" />
              </IconButton>
            </Link>
          )}
          <AuthorizedUserProfile hideActions={false} />
        </>
      )}
      <Modal
        title={title}
        open={isOpen || open}
        onClose={handleClose}
        bodyProps={{
          className: "max-w-[1200px] md:w-[600px]",
        }}
      >
        <div className="w-full max-w-[588px] flex gap-4">
          <div className="flex-grow">
            <ProjectSharingInput
              value={users}
              onChange={(value) => {
                const trimmed = value.map((v) => v.trim());
                setUsers(trimmed);
              }}
            />
            <Typography
              variant="caption2"
              className="font-semibold text-gray-300 pt-2"
            >
              Press ENTER to insert each email address.
            </Typography>
          </div>
          <ProjectSharingRoleSelect
            value={defaultRole && !role ? defaultRole : role}
            options={rolesAvailable}
            onChange={(_, value) => setRole(value)}
            className={clsxm({ hidden: !eligibleUsers.length })}
          />
        </div>
        {usersWithAccess?.items && usersWithAccess?.items?.length > 0 && (
          <ProjectSharingPeopleWithAccess
            className="mt-4"
            data={usersWithAccess?.items}
            projectId={projectId}
          />
        )}
        {sharingIsLoading && <Loading className="mt-4" size={20} />}
        <div className="flex flex-col md:flex-row gap-2 mt-8">
          <Button
            disabled={isCopied}
            className="w-full order-2 md:order-1"
            variant="outlined"
            size="large"
            endIcon={
              isCopied ? <Done className="w-4" /> : <IconLink className="w-4" />
            }
            onClick={async () => {
              if (navigator?.clipboard && window?.location?.href) {
                const url = projectId
                  ? `${window.location.origin}/project/${projectId}/dataroom/vdr`
                  : window.location.href;

                await navigator.clipboard.writeText(url);
                enqueueSnackbar("The link has been copied", {
                  title: "Copied!",
                  variant: "success",
                });
                setIsCopied(true);
              }
            }}
          >
            {isCopied ? "Copied" : "Copy link"}
          </Button>
          <Button
            className="w-full order-1 md:order-2"
            variant="contained"
            color="primary"
            size="large"
            endIcon={
              isLoading ? (
                <CircularProgress size={16} />
              ) : (
                <Done className="w-4" />
              )
            }
            disabled={!users.length || !users.some(validate) || isLoading}
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectSharing;
