import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/rootReducer";
import {
  ConfirmTemplateDeletionParams,
  IChangeReportLanguageModalParams,
  IChangeReportToneOfVoiceModalParams,
  ICreateOrEditTemplateModalParams,
  ICreateReportModuleModalParams,
  IEditReportModalParams,
  IEditReportModuleModalParams,
  IEditTemplateOriginalContentModalParams,
  IEditTemplateSchemaModalParams,
  IExportReportModalParams,
  IReassignOwnerModalParams,
  IRenameModalParams,
  OpenDocumentViewerParams,
} from "ui/pages/ProjectPage/ProjectReports/ProjectReports.types";

export interface ProjectReportsState {
  isMenuCollapsed: boolean;
  createOrEditTemplateModalParams: ICreateOrEditTemplateModalParams;
  createReportModuleModalParams: ICreateReportModuleModalParams;
  editTemplateSchemaModalParams: IEditTemplateSchemaModalParams;
  editTemplateOriginalContentModalParams: IEditTemplateOriginalContentModalParams;
  exportReportModalParams: IExportReportModalParams;
  changeReportLanguageModalParams: IChangeReportLanguageModalParams;
  changeReportToneOfVoiceModalParams: IChangeReportToneOfVoiceModalParams;
  editReportModuleModalParams: IEditReportModuleModalParams;
  editReportModalParams: IEditReportModalParams;
  createReportModalParams:
    | {
        isOpen: true;
        presetId: string;
      }
    | { isOpen: false; presetId?: never };
  renameModalParams: IRenameModalParams;
  isCreateTemplateModalOpen: boolean;
  reassignOwnerModalParams: IReassignOwnerModalParams;
  confirmTemplateDeletion: ConfirmTemplateDeletionParams;
  openDocumentViewer: OpenDocumentViewerParams;
  currentProjectId?: string;
}

const initialState: ProjectReportsState = {
  isMenuCollapsed: false,
  changeReportLanguageModalParams: { isOpen: false },
  changeReportToneOfVoiceModalParams: { isOpen: false },
  createReportModuleModalParams: { isOpen: false },
  createOrEditTemplateModalParams: { isOpen: false },
  editTemplateSchemaModalParams: { isOpen: false },
  editTemplateOriginalContentModalParams: { isOpen: false },
  exportReportModalParams: { isOpen: false },
  createReportModalParams: { isOpen: false },
  editReportModalParams: { isOpen: false },
  editReportModuleModalParams: { isOpen: false },
  renameModalParams: { isOpen: false, type: "notSelected", initialValue: "" },
  isCreateTemplateModalOpen: false,
  reassignOwnerModalParams: { isOpen: false },
  confirmTemplateDeletion: {
    isOpen: false,
  },
  openDocumentViewer: {
    isOpen: false,
  },
};

const projectReportsSlice = createSlice({
  name: "projectReports",
  initialState,
  reducers: {
    toggleMenu(
      state: ProjectReportsState,
      { payload }: PayloadAction<boolean | undefined>
    ) {
      if (typeof payload === "boolean") {
        state.isMenuCollapsed = !payload;
        return;
      }

      state.isMenuCollapsed = !state.isMenuCollapsed;
    },
    setChangeReportLanguageModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IChangeReportLanguageModalParams>
    ) {
      state.changeReportLanguageModalParams = payload;
    },
    setChangeReportToneOfVoiceModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IChangeReportToneOfVoiceModalParams>
    ) {
      state.changeReportToneOfVoiceModalParams = payload;
    },
    setCreateReportModuleModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<ICreateReportModuleModalParams>
    ) {
      state.createReportModuleModalParams = payload;
    },
    setCreateOrUpdateTemplateModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<ICreateOrEditTemplateModalParams>
    ) {
      state.createOrEditTemplateModalParams = payload;
    },
    setEditTemplateSchemaModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IEditTemplateSchemaModalParams>
    ) {
      state.editTemplateSchemaModalParams = payload;
    },
    setEditTemplateOriginalContentModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IEditTemplateOriginalContentModalParams>
    ) {
      state.editTemplateOriginalContentModalParams = payload;
    },
    setExportReportModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IExportReportModalParams>
    ) {
      state.exportReportModalParams = payload;
    },
    setCreateReportModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<ProjectReportsState["createReportModalParams"]>
    ) {
      state.createReportModalParams = payload;
    },
    setRenameModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IRenameModalParams>
    ) {
      state.renameModalParams = payload;
    },
    setCreateTemplateModalOpen(
      state: ProjectReportsState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isCreateTemplateModalOpen = payload;
    },
    setEditReportModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IEditReportModalParams>
    ) {
      state.editReportModalParams = payload;
    },
    setEditReportModuleModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IEditReportModuleModalParams>
    ) {
      state.editReportModuleModalParams = payload;
    },
    reset(state: ProjectReportsState) {
      const currentProjectId = state.currentProjectId;
      return currentProjectId
        ? { ...initialState, currentProjectId }
        : initialState;
    },
    setReassignOwnerModalParams(
      state: ProjectReportsState,
      { payload }: PayloadAction<IReassignOwnerModalParams>
    ) {
      state.reassignOwnerModalParams = payload;
    },
    confirmTemplateDeletion: (
      state: ProjectReportsState,
      { payload }: PayloadAction<ConfirmTemplateDeletionParams>
    ) => {
      state.confirmTemplateDeletion = payload;
    },
    openDocumentViewer: (
      state: ProjectReportsState,
      { payload }: PayloadAction<OpenDocumentViewerParams>
    ) => {
      state.openDocumentViewer = payload;
    },
    setCurrentProject: (
      state: ProjectReportsState,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.currentProjectId = payload;
    },
    resetCurrentProjectId: (state: ProjectReportsState) => {
      state.currentProjectId = undefined;
    },
    closeAllDrawers: (state: ProjectReportsState) => {
      state.createOrEditTemplateModalParams.isOpen = false;
      state.editReportModuleModalParams.isOpen = false;
      state.createReportModuleModalParams.isOpen = false;
    },
  },
});

export const {
  toggleMenu,
  setCreateOrUpdateTemplateModalParams,
  setEditTemplateSchemaModalParams,
  setEditTemplateOriginalContentModalParams,
  setExportReportModalParams,
  setCreateReportModalParams,
  setRenameModalParams,
  setCreateTemplateModalOpen,
  setEditReportModalParams,
  setEditReportModuleModalParams,
  setCreateReportModuleModalParams,
  setReassignOwnerModalParams,
  reset,
  confirmTemplateDeletion,
  openDocumentViewer,
  setCurrentProject,
  resetCurrentProjectId,
  setChangeReportLanguageModalParams,
  setChangeReportToneOfVoiceModalParams,
  closeAllDrawers,
} = projectReportsSlice.actions;

export const selectProjectReportsState = (state: RootState) =>
  state.projectReports;

export const selectCurrentProjectId = createSelector(
  selectProjectReportsState,
  (state) => state.currentProjectId
);

export const selectIsMenuCollapsed = createSelector(
  selectProjectReportsState,
  (state) => state.isMenuCollapsed
);

export default projectReportsSlice.reducer;
