import { ReportPresetScopeType } from "domain/project";
import { IReorderContentPayload } from "types";
import { IAssistantThread } from "types/assistant.service";
import {
  IChangeLanguageWorkflow,
  IChangeToneOfVoiceWorkflow,
  ICreateProjectPreset,
  ICreateProjectPresetTemplate,
  ICreateReportModuleRequest,
  ICreateReportRequest,
  ICreateReportWorkflow,
  IExportFileFormat,
  IProjectJob,
  IProjectReport,
  IProjectReportModule,
  IProjectReportPreset,
  IProjectReportPresetTemplate,
  IReportDefinitions,
  IReportExport,
  ITemplateSchemaResponse,
  IUpdateProjectModule,
  IUpdateProjectPresetTemplate,
  IUpdateReportRequest,
  ProjectReportTemplatizeResponse,
} from "types/project.service";
import { apiClient } from "services/clients";

class ProjectReportService {
  private readonly basePath = (projectId: string) =>
    `/Project/${projectId}/report-builder`;

  async getReports(projectId: string): Promise<IProjectReport[]> {
    const { data } = await apiClient.get(`${this.basePath(projectId)}/reports`);

    return data.response;
  }

  async getReport(
    projectId: string,
    reportId: string
  ): Promise<IProjectReport> {
    const { data } = await apiClient.get(
      `${this.basePath(projectId)}/reports/${reportId}?includeValidations=true`
    );

    return data.response;
  }

  async getPreset(
    projectId: string,
    presetId: string
  ): Promise<IProjectReportPreset> {
    const { data } = await apiClient.get(
      `${this.basePath(projectId)}/presets/${presetId}`
    );

    return data.response;
  }

  async getReportPresets(projectId: string): Promise<IProjectReportPreset[]> {
    const { data } = await apiClient.get(`${this.basePath(projectId)}/presets`);

    return data.response;
  }

  async getReportPreset(
    projectId: string,
    presetId: string
  ): Promise<IProjectReportPreset> {
    const { data } = await apiClient.get(
      `${this.basePath(projectId)}/presets/${presetId}`
    );

    return data.response;
  }

  async createReportPreset(
    projectId: string,
    payload: ICreateProjectPreset
  ): Promise<IProjectReportPreset> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/presets`,
      payload
    );

    return data.response;
  }

  async getReportPresetTemplate(
    projectId: string,
    presetId: string,
    templateId: string
  ): Promise<IProjectReportPresetTemplate> {
    const { data } = await apiClient.get(
      `${this.basePath(projectId)}/presets/${presetId}/templates/${templateId}`
    );

    return data.response;
  }

  async createReportPresetTemplate(
    projectId: string,
    presetId: string,
    payload: ICreateProjectPresetTemplate
  ): Promise<IProjectReportPresetTemplate> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/presets/${presetId}/templates`,
      payload
    );

    return data.response;
  }

  async createReport(
    projectId: string,
    paylod: ICreateReportRequest
  ): Promise<IProjectReport> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/reports`,
      paylod
    );

    return data.response;
  }

  async createPresetJob(
    projectId: string,
    presetId: string,
    file?: File
  ): Promise<IProjectJob> {
    const formData = new FormData();

    if (file) formData.append("formFile", file);

    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/presets/${presetId}/jobs`,
      formData
    );

    return data.response;
  }

  async createReportJob(
    projectId: string,
    reportId: string,
    presetId: string
  ): Promise<IProjectJob> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/reports/${reportId}/jobs`,
      {
        presetId,
      }
    );

    return data.response;
  }

  async getJob(projectId: string, jobId: string): Promise<IProjectJob> {
    const { data } = await apiClient.get(
      `${this.basePath(projectId)}/jobs/${jobId}`
    );

    return data.response;
  }

  async patchReport(
    projectId: string,
    reportId: string,
    paylod: IUpdateReportRequest
  ): Promise<IProjectReport> {
    const { data } = await apiClient.patch(
      `${this.basePath(projectId)}/reports/${reportId}`,
      paylod
    );

    return data.response;
  }

  async renameReportPreset(
    projectId: string,
    presetId: string,
    name: string
  ): Promise<IProjectReportPreset> {
    const { data } = await apiClient.patch(
      `${this.basePath(projectId)}/presets/${presetId}`,
      {
        name,
      }
    );

    return data.response;
  }

  async updateReportPresetTemplate(
    projectId: string,
    presetId: string,
    moduleTemplateId: string,
    payload: IUpdateProjectPresetTemplate
  ): Promise<IProjectReportPresetTemplate> {
    const { data } = await apiClient.patch(
      `${this.basePath(
        projectId
      )}/presets/${presetId}/templates/${moduleTemplateId}`,
      payload
    );

    return data.response;
  }

  async getReportModule(
    projectId: string,
    reportId: string,
    moduleId: string
  ): Promise<IProjectReportModule> {
    const { data } = await apiClient.get(
      `${this.basePath(projectId)}/reports/${reportId}/modules/${moduleId}`
    );

    return data.response;
  }

  async renameReportModule(
    projectId: string,
    reportId: string,
    moduleId: string,
    name: string
  ): Promise<IProjectReportModule> {
    const { data } = await apiClient.patch(
      `${this.basePath(projectId)}/reports/${reportId}/modules/${moduleId}`,
      {
        name,
      }
    );

    return data.response;
  }

  async updateReportModule(
    projectId: string,
    reportId: string,
    moduleId: string,
    payload: IUpdateProjectModule
  ): Promise<IProjectReportModule> {
    const { data } = await apiClient.patch(
      `${this.basePath(projectId)}/reports/${reportId}/modules/${moduleId}`,
      payload
    );

    return data.response;
  }

  async regenerateReportModule(
    projectId: string,
    reportId: string,
    moduleId: string
  ): Promise<IProjectReportModule> {
    const { data } = await apiClient.post(
      `${this.basePath(
        projectId
      )}/reports/${reportId}/modules/${moduleId}/generate`
    );

    return data.response;
  }

  async revalidateReportModule(
    projectId: string,
    reportId: string,
    moduleId: string
  ): Promise<void> {
    await apiClient.post(
      `${this.basePath(
        projectId
      )}/reports/${reportId}/modules/${moduleId}/validation`
    );
  }

  async deleteReport(projectId: string, reportId: string): Promise<void> {
    await apiClient.delete(`${this.basePath(projectId)}/reports/${reportId}`);
  }

  async deleteReportModule(
    projectId: string,
    reportId: string,
    moduleId: string
  ): Promise<void> {
    await apiClient.delete(
      `${this.basePath(projectId)}/reports/${reportId}/modules/${moduleId}`
    );
  }

  async deleteReportPreset(projectId: string, presetId: string): Promise<void> {
    await apiClient.delete(`${this.basePath(projectId)}/presets/${presetId}`);
  }

  async deleteReportPresetTemplate(
    projectId: string,
    presetId: string,
    templateId: string
  ): Promise<void> {
    await apiClient.delete(
      `${this.basePath(projectId)}/presets/${presetId}/templates/${templateId}`
    );
  }

  async reorderReportModule(
    projectId: string,
    reportId: string,
    moduleId: string,
    payload: IReorderContentPayload
  ): Promise<void> {
    await apiClient.patch(
      `${this.basePath(
        projectId
      )}/reports/${reportId}/modules/${moduleId}/order`,
      payload
    );
  }

  async reorderReportPresetTemplate(
    projectId: string,
    presetId: string,
    templateId: string,
    payload: IReorderContentPayload
  ): Promise<void> {
    await apiClient.patch(
      `${this.basePath(
        projectId
      )}/presets/${presetId}/templates/${templateId}/order`,
      payload
    );
  }

  async cloneReportPreset(
    projectId: string,
    presetId: string
  ): Promise<IProjectReportPreset> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/presets/${presetId}/clone`
    );

    return data.response;
  }

  async promotePreset(
    projectId: string,
    presetId: string,
    scopeType: ReportPresetScopeType
  ): Promise<IProjectReportPreset> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/presets/${presetId}/promote/${scopeType}`
    );

    return data.response;
  }

  async reassignOwnership(
    projectId: string,
    presetId: string,
    ownerId: string
  ): Promise<IProjectReportPreset> {
    const { data } = await apiClient.patch(
      `${this.basePath(projectId)}/presets/${presetId}/owner`,
      {
        ownerId,
      }
    );

    return data.respose;
  }

  async retryPresetGeneration(
    projectId: string,
    presetId: string
  ): Promise<IProjectJob> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/presets/${presetId}/retry`
    );

    return data.response;
  }

  async retryReportGeneration(
    projectId: string,
    reportId: string
  ): Promise<IProjectJob> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/reports/${reportId}/retry`
    );

    return data.response;
  }

  async templatizeChatThread(
    projectId: string,
    threadId: string,
    reportPresetId: string
  ): Promise<ProjectReportTemplatizeResponse> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/threads/${threadId}/templates`,
      {
        reportPresetId,
        isStatic: false,
      }
    );
    return data.response;
  }

  async getReportDefinitions(projectId: string): Promise<IReportDefinitions> {
    const response = await apiClient.get(
      `${this.basePath(projectId)}/reports/definitions`
    );
    return response?.data?.response;
  }

  async createReportThread(
    projectId: string,
    reportId: string,
    moduleId: string,
    initModule?: boolean
  ): Promise<IAssistantThread> {
    const { data } = await apiClient.post(
      `${this.basePath(
        projectId
      )}/reports/${reportId}/modules/${moduleId}/threads`,
      undefined,
      initModule
        ? {
            params: { initModule },
          }
        : undefined
    );

    return data.response;
  }

  async getTemplateSchema(
    projectId: string,
    presetId: string,
    templateId: string
  ): Promise<ITemplateSchemaResponse> {
    const { data } = await apiClient.get(
      `${this.basePath(
        projectId
      )}/presets/${presetId}/templates/${templateId}/schema`
    );

    return data.response;
  }

  async updateTemplateSchema(
    projectId: string,
    presetId: string,
    templateId: string,
    payload: { templateSchema?: string; originalContent?: string }
  ): Promise<IProjectReportPresetTemplate> {
    const { data } = await apiClient.patch(
      `${this.basePath(
        projectId
      )}/presets/${presetId}/templates/${templateId}/schema`,
      payload
    );

    return data.response;
  }

  async exportReport(
    projectId: string,
    reportId: string,
    exportFormats: IExportFileFormat[]
  ): Promise<IReportExport> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/reports/${reportId}/export`,
      {
        formats: exportFormats,
      }
    );
    return data.response;
  }

  async createReportModule(
    projectId: string,
    reportId: string,
    payload: ICreateReportModuleRequest
  ): Promise<IProjectReportModule> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/reports/${reportId}/modules`,
      payload
    );

    return data.response;
  }

  async changeReportLanguage(
    projectId: string,
    reportId: string,
    language: string
  ): Promise<IProjectJob> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/reports/${reportId}/workflow`,
      {
        workflowJobs: [
          {
            name: "translate",
            payload: { language },
          } as IChangeLanguageWorkflow,
        ],
      } as ICreateReportWorkflow
    );

    return data.response;
  }

  async changeReportToneOfVoice(
    projectId: string,
    reportId: string,
    toneOfVoice: string
  ): Promise<IProjectJob> {
    const { data } = await apiClient.post(
      `${this.basePath(projectId)}/reports/${reportId}/workflow`,
      {
        workflowJobs: [
          {
            name: "toneOfVoice",
            payload: { toneOfVoice },
          } as IChangeToneOfVoiceWorkflow,
        ],
      } as ICreateReportWorkflow
    );

    return data.response;
  }

  async getReportWorkflowOptions<T>(
    projectId: string,
    workflowName: string
  ): Promise<T> {
    const { data } = await apiClient.get(
      `${this.basePath(projectId)}/workflow/${workflowName}/options`
    );

    return data.response;
  }
}

export default new ProjectReportService();
