import { createTheme } from "@mui/material/styles";
import { getTheme } from "./theme";
import { bullpenTailwindPreset } from "tailwind-preset";
import paletteVariables from "../styles/_variables.scss";

export const bullpenTheme = (
  colors: IBrandingColors,
  tailwindPreset: TailwindPreset = bullpenTailwindPreset
) => {
  const theme = getTheme(colors, tailwindPreset);

  return createTheme(theme as any, {
    palette: {
      background: {
        default: "#fcfcfd",
      },
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      body1: {
        fontFamily: "Poppins, sans-serif",
      },
      body2: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "0.875rem",
        lineHeight: "24px",
      },
      button: {
        fontFamily: "Poppins, sans-serif",
      },
      h1: {
        fontFamily: "Poppins, sans-serif",
      },
      h2: {
        fontFamily: "Poppins, sans-serif",
      },
      h3: {
        fontFamily: "Poppins, sans-serif",
      },
      h4: {
        margin: "0",
        fontFamily: "Poppins, sans-serif",
      },
      h5: {
        fontFamily: "Poppins, sans-serif",
      },
      h6: {
        fontFamily: "Poppins, sans-serif",
      },
      hairline1: {
        fontFamily: "Poppins, sans-serif",
      },
      hairline2: {
        fontFamily: "Poppins, sans-serif",
      },
      caption: {
        fontFamily: "Poppins, sans-serif",
      },
      overline: {
        fontFamily: "Poppins, sans-serif",
      },
      subtitle1: {
        fontFamily: "Poppins, sans-serif",
      },
      subtitle2: {
        fontFamily: "Poppins, sans-serif",
      },
      button1: {
        fontWeight: "bold",
        fontSize: "1rem",
        lineHeight: "1rem",
        color: theme.customColors.primary[400],
      },
      button2: {
        fontWeight: "bold",
        fontSize: "0.875rem",
        lineHeight: "1rem",
        color: theme.customColors.primary[400],
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&.MuiButton-containedPrimary": {
              color: paletteVariables["bullpen-secondary-950"],
              border: "2px solid rgba(0, 0, 0, 0.1)",

              ".MuiButton-startIcon path, .MuiButton-endIcon path": {
                fill: paletteVariables["bullpen-secondary-950"],
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderColor: "rgba(0, 0, 0, 0.1)",
            "&.MuiIconButton-colorPrimary": {
              border: "2px solid rgba(0, 0, 0, 0.1)",
              color: paletteVariables["bullpen-secondary-950"],
              backgroundColor: colors.mainColor,

              "&:hover": {
                backgroundColor: colors.mainColor200,
              },
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            fontFamily: "Poppins, sans-serif",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "14px",
            fontWeight: 600,
            textTransform: "none",
            transform: "none",
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              borderColor: "#777e91",
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: theme.customColors.primary[400],
            },
          },
        },
      },
    },
  });
};
