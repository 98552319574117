import { FC } from "react";
import { Button, Typography } from "@mui/material";
import { generatePath } from "react-router-dom";
import { IMessageAnnotation } from "types/assistant.service";
import { ResourceType } from "types/resource.service";
import * as paths from "ui/Router/paths";
import { useDealAlias } from "ui/hooks";

type FileCitationItem = Pick<
  IMessageAnnotation,
  "fileId" | "fileKey" | "fileName"
>;
interface FileCitationProps {
  fileCitation: FileCitationItem;
  index: number;
  type?: ResourceType;
}

export const FileCitation: FC<FileCitationProps> = ({
  fileCitation,
  index,
  type,
}) => {
  const dealAlias = useDealAlias();

  function getDataroomPath(fileCitation: FileCitationItem): string | undefined {
    if (!fileCitation.fileKey || !fileCitation.fileName) {
      return undefined;
    }

    const folder = fileCitation.fileKey.replace(fileCitation.fileName, "");
    switch (type) {
      case "Project":
        return generatePath(paths.project.projectDataroomVDRDirectoriesFolder, {
          alias: dealAlias,
          folder: folder,
        });
      case "fund":
        return generatePath(paths.dealDataroomDirectoriesFolder, {
          alias: dealAlias,
          folder: folder,
        });
      default:
        return undefined;
    }
  }

  function onFileCitationClick(fileCitation: FileCitationItem) {
    const dataroomPath = getDataroomPath(fileCitation);
    if (dataroomPath && fileCitation.fileName) {
      const openFileParam = new URLSearchParams({
        openFile: fileCitation.fileName,
      });
      const openFilePath = `/${dataroomPath}?${openFileParam.toString()}`;
      window.open(openFilePath, "_blank");
    }
  }
  return (
    <Button
      variant="text"
      color="primary"
      onClick={() => onFileCitationClick(fileCitation)}
      disabled={!fileCitation.fileKey || !type}
      className="h-6 px-1"
    >
      <Typography className="font-bold text-gray-500 text-sm">
        {`[${index + 1}:${fileCitation.fileName || "Deleted"}]`}
      </Typography>
    </Button>
  );
};
