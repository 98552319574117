import { Typography } from "@mui/material";
import { clsxm } from "application/utils";
import { ReactComponent as ConversationsIcon } from "assets/svg/conversations.svg";
import { Children, FC, ReactNode, useState } from "react";
import { EnterButton } from "ui/components/Button";
import { EmptyContentScreen } from "ui/components/EmptyContentScreen";
import { SearchInput } from "ui/components/SearchInput";

interface MessageListInputFormProps {
  onAddMessage: (message: string) => void;
  size?: "small" | "medium";
  children?: ReactNode;
}

export const MessageListInputForm: FC<MessageListInputFormProps> = ({
  onAddMessage,
  size = "small",
  children,
}) => {
  const [newMessage, setNewMessage] = useState("");

  const isSmall = size === "small";

  const handleAddMessage = () => {
    if (!newMessage.trim()) return;

    onAddMessage?.(newMessage);
    setNewMessage("");
  };

  return (
    <div className="flex flex-col h-full">
      <div className="relative grow overflow-hidden">
        <div
          className={clsxm(
            "absolute w-full left-0 top-0 h-full overflow-auto flex gap-2 flex-col-reverse pt-2",
            isSmall ? "pb-2" : "pb-4"
          )}
        >
          {children && Children.count(children) > 0 ? (
            Children.toArray(children).reverse()
          ) : (
            <div className="h-full w-full flex items-center justify-center overflow-hidden">
              <EmptyContentScreen
                title="No messages"
                subtitle="Add a message to get started"
                icon={<ConversationsIcon />}
                className="min-h-0 h-full"
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-auto pt-2 shrink-0 w-full">
        <SearchInput
          value={newMessage}
          onKeyDown={(e) => {
            if (e.key !== "Enter" || e.shiftKey) return;

            e.preventDefault();

            handleAddMessage();
          }}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Add new message"
          className="py-1 w-full"
          multiline
          rows={2}
          customButton={
            <EnterButton
              onClick={handleAddMessage}
              className="h-4 px-5 self-start"
            >
              <Typography variant="button2" className="text-inherit">
                Add
              </Typography>
            </EnterButton>
          }
        />
      </div>
    </div>
  );
};
