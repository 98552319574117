import { Avatar, Typography, useTheme } from "@mui/material";
import { FeedbackError } from "application/errors";
import { isBullpen, isEmerge9 } from "application/platformConfiguration";
import { cleanAssistantMessage, getNameInitials } from "application/utils";
import e9Logo from "assets/svg/logo-e9-dark-no-text.svg";
import bullpenLogo from "bullpen/assets/bullpen-logo-icon.svg";
import clsx from "clsx";
import { LexicalEditor } from "lexical";
import { uniqBy } from "lodash";
import Markdown from "markdown-to-jsx";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IAssistantMessage } from "types/assistant.service";
import { ResourceType } from "types/resource.service";
import { ConfirmationModal } from "ui/components/Modal";
import { PlainTextEditor } from "ui/components/PlainTextEditor";
import {
  $getPlainTextEditorContent,
  $initializePlainTextEditorValue,
} from "ui/components/PlainTextEditor/PlainTextEditor.actions";
import { useErrorHandler, useSyndicationLayout } from "ui/hooks";
import { AssistantMessageContent } from "../AssistantMessageContent";
import AssistantMessageToolbar from "../AssistantMessageToolbar/AssistantMessageToolbar";
import { FileCitation } from "../FileCitation";
import { FunctionCitation } from "../FunctionCitation";
import { MarkdownSyntaxHighlight } from "../MarkdownSyntaxHighlight";
import styles from "./AssistantMessage.module.scss";

interface AssistantMessageProps {
  message: Pick<
    IAssistantMessage,
    "messageId" | "role" | "content" | "annotations" | "functionsMetadata"
  >;
  showOptions: boolean;
  onEdit?: (messageId: string, content: string) => Promise<void>;
  onDelete?: (messageId: string) => Promise<void>;
  isUserMessage: boolean;
  type?: ResourceType;
  disableParsingRawHTML?: boolean;
}

export type AssistantMessageHeaderProps =
  | {
      isUserMessage: boolean;
      userInformation: {
        fullName?: string;
        imageUrl?: string;
        shortName?: string;
      };
    }
  | {
      isUserMessage?: false;
      userInformation?: never;
    };

export const AssistantMessageHeader: FC<AssistantMessageHeaderProps> = ({
  isUserMessage,
  userInformation,
}) => {
  const layout = useSyndicationLayout();

  const logoSrc = useMemo(() => {
    if (layout.isSyndicate) {
      return layout.mainInfo.logo.logoSquare as string;
    }

    return (isEmerge9() ? e9Logo : bullpenLogo) as unknown as string;
  }, [layout]);

  return (
    <div className="flex space-x-2 items-center mb-3">
      <Avatar
        className={clsx(
          isUserMessage ? "bg-gray-300 text-white" : "bg-primary text-white",
          "h-6 w-6 text-[1rem] font-sans !shadow-sm",
          { "p-1": !isUserMessage && isBullpen() }
        )}
        variant={isUserMessage ? "circular" : "rounded"}
        src={isUserMessage ? userInformation.imageUrl : logoSrc}
      >
        {isUserMessage ? getNameInitials(userInformation.fullName) : null}
      </Avatar>
      <Typography variant="caption" className="font-bold text-dark-text">
        {isUserMessage
          ? userInformation.shortName || userInformation.fullName
          : `${layout.platformShortName} AI Assistant`}
      </Typography>
    </div>
  );
};

export const AssistantMessage: FC<AssistantMessageProps> = ({
  message,
  showOptions,
  onEdit,
  onDelete,
  isUserMessage,
  type,
  disableParsingRawHTML,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useErrorHandler();

  const editorRef = useRef<LexicalEditor>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const markdownRef = useRef<HTMLDivElement>(null);

  const [isEditing, setEditing] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const resetEdit = useCallback(() => {
    if (!editorRef.current) return;

    editorRef.current?.update(() => {
      $initializePlainTextEditorValue(message.content);
    });

    setEditing(false);
  }, [message.content]);

  const handleEdit = useCallback(async () => {
    if (!onEdit) return;
    if (!editorRef.current) {
      throw new Error(
        "It was not possible to edit because the reference could not be found"
      );
    }

    setSubmitting(true);

    editorRef.current.read(async () => {
      try {
        const content = $getPlainTextEditorContent();

        if (!content) {
          throw new FeedbackError(
            "Please insert a valid value for the message."
          );
        }

        await onEdit(message.messageId, content);

        setEditing(false);

        enqueueSnackbar("The message has been successfully updated.", {
          title: "Message updated",
          variant: "success",
        });
      } catch (e) {
        handleError(
          e,
          "It was not possible to edit the message. Please try again later"
        );
        resetEdit();
      } finally {
        setSubmitting(false);
      }
    });
  }, [enqueueSnackbar, handleError, message.messageId, onEdit, resetEdit]);

  const handleDelete = useCallback(async () => {
    if (!onDelete) return;

    setSubmitting(true);

    try {
      await onDelete(message.messageId);

      enqueueSnackbar("The message has been successfully deleted.", {
        title: "Message deleted",
        variant: "success",
      });
    } catch (e) {
      handleError(
        e,
        "It was not possible to delete the message. Please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  }, [enqueueSnackbar, handleError, message.messageId, onDelete]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (!isEditing) return;

    const content = wrapperRef.current;

    const callback: EventListener = (event) => {
      if (event.target && !content.contains(event.target as any)) {
        resetEdit();
      }
    };

    document.addEventListener("click", callback);

    return () => document.removeEventListener("click", callback);
  }, [isEditing, resetEdit]);

  const copyContent = useCallback(async (reference: HTMLElement | null) => {
    if (!reference) return;

    try {
      const html = reference.innerHTML;
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([html], { type: "text/html" }),
          "text/plain": new Blob([reference.textContent || ""], {
            type: "text/plain",
          }),
        }),
      ]);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }, []);

  const functionsMetadata = useMemo(() => {
    if (!message.functionsMetadata) {
      return [];
    }
    return message.functionsMetadata.filter((m) => m.source);
  }, [message]);

  const fileCitations = useMemo(() => {
    return uniqBy(message.annotations, "fileId");
  }, [message]);

  const messageContent = useMemo(() => {
    if (message.annotations && message.annotations.length > 0) {
      return message.annotations.reduce(
        (content, current) =>
          content.replace(
            current.text,
            ` <span style="color: ${theme.palette.primary.dark}">**[${
              fileCitations.findIndex((f) => f.fileId === current.fileId) + 1
            }]**</span> `
          ),
        message.content
      );
    }
    return cleanAssistantMessage(message.content);
  }, [message, fileCitations, theme]);

  return (
    <div className="flex w-full items-start justify-between">
      <Typography component="span" variant="caption" className="text-dark-text">
        {isUserMessage ? (
          <div
            ref={wrapperRef}
            className="flex flex-col"
            key={message.messageId}
          >
            <AssistantMessageContent isUserMessage>
              <>
                <PlainTextEditor
                  initialContent={message.content}
                  className={styles.Editable}
                  onEscape={resetEdit}
                  editorRef={editorRef}
                  editable={isEditing}
                  variant="caption"
                  autoFocus
                />
              </>
            </AssistantMessageContent>
            {showOptions && (
              <div className="mt-2">
                <AssistantMessageToolbar
                  edit={
                    onEdit && {
                      isEditing,
                      isSubmitting,
                      onToggle: () => {
                        if (isEditing) {
                          return resetEdit();
                        }

                        setEditing(true);
                      },
                      onSubmit: handleEdit,
                    }
                  }
                  onDelete={onDelete && (() => setDeleteModalOpen(true))}
                  onCopy={() => {
                    const rootElement = editorRef.current?.getRootElement();
                    if (!rootElement) return;

                    copyContent(rootElement);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col">
            <Markdown
              options={{
                forceWrapper: true,
                disableParsingRawHTML,
                wrapper: (props) => (
                  <span
                    {...props}
                    ref={markdownRef}
                    className={clsx(styles.Message)}
                  />
                ),
                overrides: {
                  code: MarkdownSyntaxHighlight,
                },
              }}
            >
              {messageContent}
            </Markdown>

            <div className="mt-2">
              <AssistantMessageToolbar
                onCopy={() => copyContent(markdownRef.current)}
              />
            </div>
          </div>
        )}

        {fileCitations.length > 0 && (
          <div className="flex flex-wrap flex-row mt-1">
            {fileCitations.map((item, index) => (
              <FileCitation
                key={index}
                fileCitation={item}
                index={index}
                type={type}
              />
            ))}
          </div>
        )}
        {functionsMetadata.length > 0 && (
          <div className="flex flex-wrap flex-row mt-1">
            {functionsMetadata.map((item, index) => (
              <FunctionCitation key={index} functionCitation={item} />
            ))}
          </div>
        )}
      </Typography>

      {isDeleteModalOpen && (
        <ConfirmationModal
          title="Delete message"
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDelete}
        >
          <Typography variant="body1" className="text-gray-500">
            Are you sure to delete the message?
          </Typography>

          <div className="mt-3">
            <AssistantMessageContent isUserMessage>
              <Typography
                variant="caption"
                className="w-fit whitespace-pre-wrap text-dark-text"
              >
                {message.content}
              </Typography>
            </AssistantMessageContent>
          </div>
        </ConfirmationModal>
      )}
    </div>
  );
};
