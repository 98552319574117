import { clsxm } from "application/utils";
import { forwardRef } from "react";
import { ReactElement } from "react";
import styles from "./AssistantMessageContent.module.scss";

interface AssistantMessageContentProps {
  className?: string;
  isUserMessage: boolean;
  children: ReactElement<any>;
}

export const AssistantMessageContent = forwardRef<
  HTMLDivElement,
  AssistantMessageContentProps
>(({ className, isUserMessage, children }, ref) => {
  if (!isUserMessage) return children;

  return (
    <div ref={ref} className={clsxm(styles.UserMessageWrapper, className)}>
      {children}
    </div>
  );
});
