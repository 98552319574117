import { PhotoLibraryRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { FC, ReactNode } from "react";
import styles from "./EmptyContentScreen.styles.module.scss";

export interface EmptyContentScreenProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  icon?: ReactNode;
  children?: React.ReactNode;
  className?: string;
}

export const EmptyContentScreen: FC<EmptyContentScreenProps> = ({
  subtitle,
  title,
  icon = <PhotoLibraryRounded />,
  children,
  className,
}) => {
  return (
    <div
      data-testid="empty-content-screen"
      className={clsx(
        styles.Content,
        "flex flex-col w-[384px] min-h-[384px] max-w-full items-center justify-center m-auto !pointer-events-auto space-y-6 my-6",
        className
      )}
    >
      <div className={styles.IconWrapper}>{icon}</div>

      <div className="space-y-2">
        {title && (
          <Typography
            component="div"
            variant="h6"
            role="heading"
            className="text-center font-serif text-dark-text font-medium"
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            component="div"
            variant="body2"
            role="complementary"
            className="text-gray-500 text-center"
          >
            {subtitle}
          </Typography>
        )}
      </div>

      {children}
    </div>
  );
};
