import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { ReactComponent as CopyIcon } from "assets/svg/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/delete-bin.svg";
import { ReactComponent as RenameIcon } from "assets/svg/rename.svg";
import { ReactComponent as CheckIcon } from "assets/svg/icon-check.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { FC } from "react";
import { CopyButton } from "ui/components/Button";
import { clsxm } from "application/utils";

interface AssistantMessageToolbarProps {
  className?: string;
  edit?: {
    isEditing: boolean;
    isSubmitting?: boolean;
    onSubmit: () => void;
    onToggle: () => void;
  };
  onDelete?: () => void;
  onCopy?: () => Promise<void> | void;
}

const AssistantMessageToolbar: FC<AssistantMessageToolbarProps> = ({
  edit,
  onDelete,
  onCopy,
  className,
}) => {
  if ([edit, onDelete, onCopy].every((prop) => !prop)) {
    return null;
  }

  if (edit?.isEditing) {
    return (
      <div className={clsxm("flex w-fit", className)}>
        <IconButton
          disabled={edit.isSubmitting}
          className="h-8 w-8 text-dark-text"
          onClick={edit.onToggle}
        >
          <CloseIcon />
        </IconButton>
        <IconButton className="h-8 w-8 text-dark-text" onClick={edit.onSubmit}>
          {edit.isSubmitting ? <CircularProgress size={16} /> : <CheckIcon />}
        </IconButton>
      </div>
    );
  }

  return (
    <div className={clsxm("flex w-fit", className)}>
      {onCopy && (
        <Tooltip title="Copy">
          <CopyButton
            icon={<CopyIcon />}
            className="h-8 w-8 text-dark-text"
            overrideCopyFn={onCopy}
          />
        </Tooltip>
      )}
      {edit && (
        <Tooltip title="Edit">
          <IconButton
            className="h-8 w-8 text-dark-text"
            onClick={edit.onToggle}
          >
            <RenameIcon />
          </IconButton>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title="Delete">
          <IconButton className="h-8 w-8 text-dark-text" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default AssistantMessageToolbar;
