import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as paths from "ui/Router/paths";
import ScrollToTop from "ui/components/ScrollToTop/ScrollToTop";
import { CommonLayout, NotFound } from "ui/pages";
import { bullpenAuthRoutes } from "./routes/bullpenAuthRoutes";
import { bullpenSignupRoutes } from "./routes/bullpenSignupRoutes";
import { useSyndicationLayout } from "ui/hooks";
import { metaConfig } from "application/platformConfiguration";
import { Helmet } from "react-helmet";
import { IntercomUpdater } from "./IntercomUpdater";

const BullpenHomePage = lazy(() => import("bullpen/pages/BullpenHomePage"));

const BullpenProductPage = lazy(
  () => import("bullpen/pages/BullpenProductPage")
);

const HelpCenter = lazy(() => import("ui/pages/HelpCenter"));

const AsyncPrivacyPolicy = lazy(
  () => import("ui/pages/PrivacyPolicy/PrivacyPolicy")
);
const AsyncTermsAndService = lazy(
  () => import("ui/pages/TermsAndService/TermsAndService")
);

const AsyncConfirmSignUp = lazy(
  () => import("ui/pages/ConfirmSignUp/ConfirmSignUp")
);
const AsyncSignIn = lazy(() => import("ui/pages/SignIn/SignIn"));

const AsyncResetPassword = lazy(
  () => import("ui/pages/ResetPassword/ResetPassword")
);

const FederatedSignIn = lazy(
  () => import("ui/pages/FederatedSignIn/FederatedSignIn")
);

const AsyncLandingAIWaitList = lazy(
  () => import("ui/pages/Landing/LandingAIWaitList")
);

const AsyncSyndicateLogin = lazy(() => import("ui/pages/SyndicationLogin"));

export const BullpenAppRouter = ({
  hasLayoutError,
}: {
  hasLayoutError: boolean;
}) => {
  const layout = useSyndicationLayout();
  const { isSyndicate } = layout;

  const favicon = layout.isSyndicate
    ? layout.mainInfo?.logo?.favicon ?? metaConfig.favicon
    : metaConfig.favicon;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <IntercomUpdater />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Routes>
        <Route
          path="/"
          element={<CommonLayout hasLayoutError={hasLayoutError} />}
        >
          {bullpenSignupRoutes}

          {bullpenAuthRoutes}

          <Route
            index
            element={
              isSyndicate ? <AsyncSyndicateLogin /> : <BullpenHomePage />
            }
          />

          <Route path={paths.bullpenProduct} element={<BullpenProductPage />} />

          <Route
            path={paths.signInPath}
            element={isSyndicate ? <AsyncSyndicateLogin /> : <AsyncSignIn />}
          />

          <Route path={paths.resetPassword} element={<AsyncResetPassword />} />

          <Route
            path={paths.confirmSignUpPath}
            element={<AsyncConfirmSignUp />}
          />

          <Route
            path={paths.oauthFederatedSignin}
            element={<FederatedSignIn />}
          />

          <Route path={paths.helpCenter} element={<HelpCenter />} />

          <Route path={paths.privacyPolicy} element={<AsyncPrivacyPolicy />} />

          <Route
            path={paths.termsOfService}
            element={<AsyncTermsAndService />}
          />

          <Route
            path={paths.landingAIWaitList}
            element={<AsyncLandingAIWaitList />}
          />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
