import { Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { BetaTag } from "../BetaTag";

interface ActionDescriptionProps {
  label: string | ReactNode;
  description: string | ReactNode;
  beta?: boolean;
}

export const ActionDescription: FC<ActionDescriptionProps> = ({
  description,
  label,
  beta,
}) => {
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex items-center space-x-2">
        <Typography
          variant="body2"
          className="font-semibold block text-primary-400 truncate"
        >
          {label}
        </Typography>
        {beta && <BetaTag />}
      </div>
      <Typography variant="caption" className="text-gray-500 block">
        {description}
      </Typography>
    </div>
  );
};
