import { lazy } from "react";
import { Route } from "react-router-dom";
import * as paths from "ui/Router/paths";
import { FeatureFlagId } from "domain/featureFlag";
import { config } from "config";
import { RestrictedByGroupOutlet } from "ui/Router/guards/RestrictedByGroupOutlet";
import { RestrictedByFeatureFlagOutlet } from "ui/Router/guards/RestrictedByFeatureFlagOutlet";
import { ProjectPageWrapper } from "ui/pages/ProjectPage/ProjectPage.wrapper";
import { ProjectGuard } from "ui/Router/guards/ProjectGuard";

const ProjectList = lazy(() => import("ui/pages/ProjectList"));

const ProjectCreate = lazy(() => import("ui/pages/ProjectCreate"));

const ProjectDataRoom = lazy(
  () => import("ui/pages/ProjectPage/ProjectDataRoom")
);

const ProjectAssistant = lazy(
  () => import("ui/pages/ProjectPage/ProjectAssistant")
);

const ProjectDataRoomPermissions = lazy(
  () => import("ui/pages/ProjectDataRoomPermissions")
);

const ProjectSettings = lazy(
  () => import("ui/pages/ProjectPage/ProjectSettings")
);

const ProjectReports = lazy(
  () => import("ui/pages/ProjectPage/ProjectReports")
);

export const projectsRoutes = [
  <Route
    key="projectsRoutes"
    element={
      config.env === "prod" ? (
        <RestrictedByGroupOutlet developer />
      ) : (
        <RestrictedByGroupOutlet admin />
      )
    }
  >
    <Route
      element={
        <RestrictedByFeatureFlagOutlet featureFlag={FeatureFlagId.Projects} />
      }
    >
      <Route path={paths.projectList} element={<ProjectList />} />
      <Route path={paths.project.projectCreate} element={<ProjectCreate />} />

      <Route path="project/:alias" element={<ProjectPageWrapper />}>
        <Route path="dataroom/vdr">
          <Route index element={<ProjectDataRoom />} />
          <Route path="*" element={<ProjectDataRoom />} />
        </Route>
        <Route path="assistant" element={<ProjectAssistant />} />
        <Route path="reports" element={<ProjectReports />} />
        <Route element={<ProjectGuard checkPermission={(p) => p.canManage} />}>
          <Route path="settings" element={<ProjectSettings />} />
        </Route>
      </Route>
      <Route element={<ProjectGuard checkPermission={(p) => p.canManage} />}>
        <Route
          path={paths.project.projectDataroomPermissions}
          element={<ProjectDataRoomPermissions />}
        />
      </Route>
    </Route>
  </Route>,
];
